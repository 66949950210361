import React from "react";

const Cardsinject = () => {
  return (
    <div className="head-inject-d2">
      <div className="head-inject-d1">
        <div className="head-inject-d3">
          <div className="head-pro3">
            <div className="pro-imggd">
              <img src="/img/products/1.jpg" alt="" />
            </div>
            <span className="pro-span1">BOLDE 250</span>

            <span className="pro-span2">EACH ML CONTAINS:</span>
            <div>
              <div className="head-di1">
                <span>Boldenone Undecylenate</span>
                <span>250 mg</span>
              </div>
              <div className="head-di1">
                <span>Oil Base</span>
                <span>q . s</span>
              </div>
            </div>
          </div>
          <div className="head-pro3">
            <div className="pro-imggd">
              <img src="/img/products/2.jpg" alt="" />
            </div>
            <span className="pro-span1">BOLDE 400</span>

            <span className="pro-span2">EACH ML CONTAINS:</span>
            <div>
              <div className="head-di1">
                <span>Boldenone Undecylenate</span>
                <span>400 mg</span>
              </div>
              <div className="head-di1">
                <span>Oil Base</span>
                <span>q . s</span>
              </div>
            </div>
          </div>

          <div className="head-pro3">
            <div className="pro-imggd">
              <img src="/img/products/3.jpg" alt="" />
            </div>
            <span className="pro-span1">DECA 250</span>

            <span className="pro-span2">EACH ML CONTAINS:</span>
            <div>
              <div className="head-di1">
                <span>Nandrolone Decanoate</span>
                <span>250 mg</span>
              </div>
              <div className="head-di1">
                <span>Oil Base</span>
                <span>q . s</span>
              </div>
            </div>
          </div>

          <div className="head-pro3">
            <div className="pro-imggd">
              <img src="/img/products/4.jpg" alt="" />
            </div>
            <span className="pro-span1">DECA 400</span>

            <span className="pro-span2">EACH ML CONTAINS:</span>
            <div>
              <div className="head-di1">
                <span>Nandrolone Decanoate</span>
                <span>400 mg</span>
              </div>

              <div className="head-di1">
                <span>Oil Base</span>
                <span>q . s</span>
              </div>
            </div>
          </div>

          <div className="head-pro3">
            <div className="pro-imggd">
              <img src="/img/products/5.jpg" alt="" />
            </div>
            <span className="pro-span1">MASTERON</span>

            <span className="pro-span2">EACH ML CONTAINS:</span>
            <div>
              <div className="head-di1">
                <span>Drostanolone Propionate</span>
                <span>100 mg</span>
              </div>

              <div className="head-di1">
                <span>Oil Base</span>
                <span>q . s</span>
              </div>
            </div>
          </div>
          <div className="head-pro3">
            <div className="pro-imggd">
              <img src="/img/products/6.jpg" alt="" />
            </div>
            <span className="pro-span1">NANDRO-P 100</span>

            <span className="pro-span2">EACH ML CONTAINS:</span>
            <div>
              <div className="head-di1">
                <span>Nandrolone Phenylpropionate</span>
                <span>100 mg</span>
              </div>

              <div className="head-di1">
                <span>Oil Base</span>
                <span>q . s</span>
              </div>
            </div>
          </div>

          <div className="head-pro3">
            <div className="pro-imggd">
              <img src="/img/products/7.jpg" alt="" />
            </div>
            <span className="pro-span1">PRIMEX 100</span>

            <span className="pro-span2">EACH ML CONTAINS:</span>
            <div>
              <div className="head-di1">
                <span>Methenolone Enanthate</span>
                <span>100 mg</span>
              </div>

              <div className="head-di1">
                <span>Oil Base</span>
                <span>q . s</span>
              </div>
            </div>
          </div>
          <div className="head-pro3">
            <div className="pro-imggd">
              <img src="/img/products/8.jpg" alt="" />
            </div>
            <span className="pro-span1">SUSTA 250</span>

            <span className="pro-span2">EACH ML CONTAINS:</span>
            <div>
              <div className="head-di1">
                <span>Testosterone Propionate</span>
                <span>30 mg</span>
              </div>
              <div className="head-di1">
                <span>Testosterone Phenylpropionate</span>
                <span>60 mg</span>
              </div>
              <div className="head-di1">
                <span>Testosterone Isocaproate</span>
                <span>60 mg</span>
              </div>
              <div className="head-di1">
                <span>Testosterone Decanoate</span>
                <span>100 mg</span>
              </div>

              <div className="head-di1">
                <span>Oil Base</span>
                <span>q . s</span>
              </div>
            </div>
          </div>
          <div className="head-pro3">
            <div className="pro-imggd">
              <img src="/img/products/9.jpg" alt="" />
            </div>
            <span className="pro-span1">TEST-C 250</span>

            <span className="pro-span2">EACH ML CONTAINS:</span>
            <div>
              <div className="head-di1">
                <span>Testosterone Cypionate</span>
                <span>250 mg</span>
              </div>

              <div className="head-di1">
                <span>Oil Base</span>
                <span>q . s</span>
              </div>
            </div>
          </div>
          <div className="head-pro3">
            <div className="pro-imggd">
              <img src="/img/products/10.jpg" alt="" />
            </div>
            <span className="pro-span1">TEST-E 250</span>

            <span className="pro-span2">EACH ML CONTAINS:</span>
            <div>
              <div className="head-di1">
                <span>Testosterone Enanthate</span>
                <span>250 mg</span>
              </div>

              <div className="head-di1">
                <span>Oil Base</span>
                <span>q . s</span>
              </div>
            </div>
          </div>
          <div className="head-pro3">
            <div className="pro-imggd">
              <img src="/img/products/11.jpg" alt="" />
            </div>
            <span className="pro-span1">TEST-E 350</span>

            <span className="pro-span2">EACH ML CONTAINS:</span>
            <div>
              <div className="head-di1">
                <span>Testosterone Enanthate</span>
                <span>350 mg</span>
              </div>

              <div className="head-di1">
                <span>Oil Base</span>
                <span>q . s</span>
              </div>
            </div>
          </div>
          <div className="head-pro3">
            <div className="pro-imggd">
              <img src="/img/products/12.jpg" alt="" />
            </div>
            <span className="pro-span1">TEST-P 100</span>

            <span className="pro-span2">EACH ML CONTAINS:</span>
            <div>
              <div className="head-di1">
                <span>Testosterone Propionate</span>
                <span>100 mg</span>
              </div>

              <div className="head-di1">
                <span>Oil Base</span>
                <span>q . s</span>
              </div>
            </div>
          </div>
          <div className="head-pro3">
            <div className="pro-imggd">
              <img src="/img/products/13.jpg" alt="" />
            </div>
            <span className="pro-span1">TREN-BLEND 200</span>

            <span className="pro-span2">EACH ML CONTAINS:</span>
            <div>
              <div className="head-di1">
                <span>Trenbolone Enanthate</span>
                <span>100 mg</span>
              </div>
              <div className="head-di1">
                <span>Trenbolone Acetate</span>
                <span>50 mg</span>
              </div>
              <div className="head-di1">
                <span>Trenbolone Hexahydrobenzylcarbonate</span>
                <span style={{ whiteSpace: "pre" }}>50 mg</span>
              </div>

              <div className="head-di1">
                <span>Oil Base</span>
                <span>q . s</span>
              </div>
            </div>
          </div>
          <div className="head-pro3">
            <div className="pro-imggd">
              <img src="/img/products/14.jpg" alt="" />
            </div>
            <span className="pro-span1">TREN-A 100</span>

            <span className="pro-span2">EACH ML CONTAINS:</span>
            <div>
              <div className="head-di1">
                <span>Trenbolone Acetate</span>
                <span>100 mg</span>
              </div>

              <div className="head-di1">
                <span>Oil Base</span>
                <span>q . s</span>
              </div>
            </div>
          </div>
          <div className="head-pro3">
            <div className="pro-imggd">
              <img src="/img/products/15.jpg" alt="" />
            </div>
            <span className="pro-span1">TREN-E 200</span>

            <span className="pro-span2">EACH ML CONTAINS:</span>
            <div>
              <div className="head-di1">
                <span>Trenbolone Enanthate</span>
                <span>200 mg</span>
              </div>

              <div className="head-di1">
                <span>Oil Base</span>
                <span>q . s</span>
              </div>
            </div>
          </div>
          <div className="head-pro3">
            <div className="pro-imggd">
              <img src="/img/products/16.jpg" alt="" />
            </div>
            <span className="pro-span1">TREN-H 100</span>

            <span className="pro-span2">EACH ML CONTAINS:</span>
            <div>
              <div className="head-di1">
                <span>Trenbolone Hexahydrobenzylcarbonate</span>
                <span
                  style={{
                    whiteSpace: " pre",
                  }}
                >
                  100 mg
                </span>
              </div>

              <div className="head-di1">
                <span>Oil Base</span>
                <span>q . s</span>
              </div>
            </div>
          </div>
          <div className="head-pro3">
            <div className="pro-imggd">
              <img src="/img/products/17.jpg" alt="" />
            </div>
            <span className="pro-span1">WINSTROL 100</span>

            <span className="pro-span2">EACH ML CONTAINS:</span>
            <div>
              <div className="head-di1">
                <span>Super Micronised Stanozolol</span>
                <span
                  style={{
                    whiteSpace: " pre",
                  }}
                >
                  100 mg
                </span>
              </div>

              <div className="head-di1">
                <span>Oil Base</span>
                <span>q . s</span>
              </div>
            </div>
          </div>
          <div className="head-pro3">
            <div className="pro-imggd">
              <img src="/img/products/18.jpg" alt="" />
            </div>
            <span className="pro-span1">WINSTROL 650</span>

            <span className="pro-span2">EACH ML CONTAINS:</span>
            <div>
              <div className="head-di1">
                <span>Boldenone Undecylenate</span>
                <span
                  style={{
                    whiteSpace: " pre",
                  }}
                >
                250 mg
                </span>
              </div>

              <div className="head-di1">
                <span>Testosterone Enanthate</span>
                <span
                  style={{
                    whiteSpace: " pre",
                  }}
                >
              250 mg
                </span>
              </div>
              <div className="head-di1">
                <span>


                Trenbolone Hexahydrobenzylcarbonate
                </span>
                <span
                  style={{
                    whiteSpace: " pre",
                  }}
                >
              100 mg
                </span>
              </div>

              <div className="head-di1">
                <span>Oil Base</span>
                <span>q . s</span>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Cardsinject;
