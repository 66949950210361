import React, { useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../../../components/Shared/Sidebar/Sidebar";
import useGlobalContext from "../../../../hooks/useGlobalContext";

const HomeHeader = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { stickyMenu } = useGlobalContext();
  return (
    <>
      <header>
        <div
          className={
            stickyMenu ? "header-menu-area sticky_menu" : "header-menu-area"
          }
        >
          <div className="container menu_wrapper">
            <div className="row align-items-center">
              <div className="col-xl-3 col-lg-3 col-md-6 col-6 d-flex align-items-center">
                <div className="logo logo-circle pos-rel">
                  <Link to="/">
                    <img src="img/logo/logo1.png" alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-xl-9 col-lg-9 col-md-6 col-6">
                <div className="header__menu f-right">
                  <nav id="mobile-menu">
                    <ul>
                      <li>
                        <Link to="/">HOME</Link>
                      </li>

                      <li>
                        <Link to="/services">PRODUCT +</Link>
                        <ul className="submenu">
                          <li>
                            <Link to="/Injectable">INJECTABLE</Link>
                          </li>
                          <li>
                            <Link to="/Oral">ORAL</Link>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <Link to="/services">COMPANY PROFILE</Link>
                      </li>
                      <li>
                        <Link to="/CheckAuthenticity">CHECK-AUTHENTICITY</Link>
                      </li>
                      <li>
                        <Link to="/contact">CONTACT</Link>
                      </li>
                    </ul>
                  </nav>
                </div>

                <div className="side-menu-icon d-lg-none text-end">
                  <button
                    onClick={handleShow}
                    className="side-toggle border-0 bg-transparent"
                  >
                    <i className="fas fa-bars"></i>{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <Sidebar show={show} handleClose={handleClose} />
    </>
  );
};

export default HomeHeader;
