import React from "react";
import HomeSIngleService from "../../../../components/HomeSIngleService/HomeSIngleService";

const HomeServices = () => {
  return (
    <>
      <section className="servcies-area gray-bg pt-115 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
              <div className="section-title text-center pos-rel mb-75">
                {/* <div className="section-icon">
                  <img
                    className="section-back-icon"
                    src="img/section/section-back-icon.png"
                    alt=""
                  />
                </div> */}
                <div className="section-text pos-rel home_ser_title">
                  <h1>PRODUCT RANGE</h1>
                </div>
                {/* <div className="section-line pos-rel">
                  <img src="img/shape/section-title-line.png" alt="" />
                </div> */}
              </div>
            </div>
          </div>
          <div className="row">
            <HomeSIngleService
              link="Injectable"
              image="https://images.unsplash.com/photo-1598300188480-626f2f79ab8d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGluamVjdGlvbnxlbnwwfHwwfHx8MA%3D%3D"
              icon="6"
              title="INJECTABLES"
            />
            <HomeSIngleService
              link="/Oral"
              image="https://images.unsplash.com/photo-1607874963930-2edecc67a25a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDR8fG1lZGljaW5lfGVufDB8fDB8fHww"
              icon="2"
              title="ORALS"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeServices;
