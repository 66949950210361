import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Collapsible from 'react-collapsible';

const Sidebar = ({ show, handleClose }) => {
   const Home = <NavLink to="/">Home</NavLink>
   const Department = <NavLink to="/Injectable">PRODUCT </NavLink>
   const Doctors = <NavLink to="/services">COMPANY PROFILE </NavLink>
   const Shop = <NavLink to="/contact">CONTACT</NavLink>
   const AUTHENTICITY = <NavLink to="/CheckAuthenticity"> CHECK-AUTHENTICITY</NavLink>

   return (
      <>

         <div >
            <Offcanvas show={show} onHide={handleClose} placement='end' className='side__bar'>
               <Offcanvas.Header closeButton>
                  {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
               </Offcanvas.Header>
               <Offcanvas.Body>


                  <Collapsible trigger={Home} triggerTagName="div"
                    >
                 
                  </Collapsible>
                  <br />

                  <Collapsible trigger={Department} triggerTagName="div"
                     triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                     <ul className="sidebar_sub_menu text-white mt-3">
                        <li><NavLink to="/Injectable">Injectable</NavLink></li>
                        <li><NavLink to="/Oral">Oral</NavLink></li>
                     
                     </ul>
                  </Collapsible>
                  <br />

                  <Collapsible trigger={Doctors} triggerTagName="div"
                   >
                  
                  </Collapsible>
                  <br />

                  <Collapsible trigger={Shop} triggerTagName="div"
                  >
                  
                  </Collapsible>
                  <br />
                  <Collapsible trigger={AUTHENTICITY} triggerTagName="div"
                     >
                  
                  </Collapsible>
                  
       

       

               </Offcanvas.Body>
            </Offcanvas>
         </div>
      </>
   );
};

export default Sidebar;