import React from "react";
import CommonPageHeader from "../../../components/CommonPageHeader/CommonPageHeader";
import Footer from "../../../components/Shared/Footer";
import HomeHeader from "../../Home/Home/HomeHeader/HomeHeader";

const ServicesOne = () => {
  return (
    <>
      <HomeHeader />
      <CommonPageHeader title="COMPANY PROFILE" subtitle="SERVICES" />
      <div className="services-d1">
        <div className="services-d2">
        <p>BioGear is a leading pharmaceutical with a strong commitment to providing innovative and
          high-quality healthcare solutions to patients worldwide. We specialize
          in the production of testosterone and human growth hormone injections
          for the treatment of hormonal imbalances.
          </p>
        
      

      <p>
            At BioGear, we understand the importance of hormonal balance in
            maintaining good health and well-being. Our testosterone and human
            growth hormone injections are formulated with the highest quality
            ingredients to ensure optimal efficacy and safety for patients. Our
            products are used to treat a range of conditions, including
            hypogonadism, delayed puberty, and growth hormone deficiency.We are
            dedicated to maintaining the highest standards of quality and safety
            in all our products. Our manufacturing facilities are equipped with
            state-of-the-art technology and adhere to strict regulatory
            guidelines to ensure that our products meet or exceed the
            expectations of healthcare professionals and patients alike. In
            addition to our focus on product innovation and quality, we place a
            strong emphasis on corporate responsibility and sustainability. We
            strive to minimize our environmental impact and ensure that our
            operations are socially responsible and ethically sound. Overall,
            BioGear is a company that is dedicated to improving the health and
            well-being of people worldwide through our high-quality testosterone
            and human growth hormone injections. We are committed to meeting the
            highest standards of quality, safety, and efficacy in all our
            products, and we strive to make a positive impact on society and the
            environment
          </p>
        </div>
      </div>

      {/* <CalculateArea/>
         <ServicesOneHiringArea/> */}
      <Footer />
    </>
  );
};

export default ServicesOne;
