import React from "react";

const OralCard = () => {
  return (
    <div className="head-inject-d2">
      <div className="head-inject-d1">
      <div className="head-inject-d3">

        <div className="head-pro3">
          <div className="pro-imggd">
            <img
              //  src="/img/products/1.jpg"
              src="img/products/New folder/1.jpg"
              alt=""
            />
          </div>
          <span className="pro-span1">ANADROL-50</span>

          <span className="pro-span2">EACH CAPSULE CONTAINS:</span>
          <div>

          <div className="head-di1">
            <span>Oxymetholone 50 mg</span>
          </div>
          <div className="head-di1">
            <span>1 Bottle of 100 Capsules</span>
          </div>
          </div>
        </div>
        <div className="head-pro3">
          <div className="pro-imggd">
            <img
              //  src="/img/products/1.jpg"
              src="img/products/New folder/2.jpg"
              alt=""
            />
          </div>
          <span className="pro-span1">ANAVAR</span>

          <span className="pro-span2">EACH CAPSULE CONTAINS:</span>
          <div>

          <div className="head-di1">
            <span>Oxandrolone 10 mg </span>
          </div>
          <div className="head-di1">
            <span>1 Bottle of 100 Capsules</span>
          </div>
          </div>
        </div>
        <div className="head-pro3">
          <div className="pro-imggd">
            <img
           
              src="img/products/New folder/3.jpg"
              alt=""
            />
          </div>
          <span className="pro-span1">CLENBUTEROL</span>

          <span className="pro-span2">EACH CAPSULE CONTAINS:</span>
          <div>

          <div className="head-di1">
            <span>Clenbuterol HCL 40 mcg </span>
          </div>
          <div className="head-di1">
            <span>1 Bottle of 100 Capsules</span>
          </div>
          </div>
        </div>
        <div className="head-pro3">
          <div className="pro-imggd">
            <img
           
              src="img/products/New folder/4.jpg"
              alt=""
            />
          </div>
          <span className="pro-span1">CYTOMEL</span>

          <span className="pro-span2">EACH CAPSULE CONTAINS:</span>
          <div>

          <div className="head-di1">
            <span>Liothyronine Sodium 25 mcg</span>
          </div>
          <div className="head-di1">
            <span>1 Bottle of 100 Capsules</span>
          </div>
          </div>
        </div>
        <div className="head-pro3">
          <div className="pro-imggd">
            <img
           
              src="img/products/New folder/5.jpg"
              alt=""
            />
          </div>
          <span className="pro-span1">DIANABOL</span>

          <span className="pro-span2">EACH CAPSULE CONTAINS:</span>
          <div>

          <div className="head-di1">
            <span>Methandienone 10 mg</span>
          </div>
          <div className="head-di1">
            <span>1 Bottle of 100 Capsules</span>
          </div>
          </div>
        </div>
        <div className="head-pro3">
          <div className="pro-imggd">
            <img
           
              src="img/products/New folder/6.jpg"
              alt=""
            />
          </div>
          <span className="pro-span1">STANOZOLOL</span>

          <span className="pro-span2">EACH CAPSULE CONTAINS:</span>
          <div>

          <div className="head-di1">
            <span>Stanozolol 10 mg</span>
          </div>
          <div className="head-di1">
            <span>1 Bottle of 100 Capsules</span>
          </div>
          </div>
        </div>
      </div>
    </div>
    </div>

  );
};

export default OralCard;
