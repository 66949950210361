import React from 'react'
import HomeHeader from '../Home/Home/HomeHeader/HomeHeader'
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader'

import Footer from '../../components/Shared/Footer'
import OralCard from './OralCard'

const Oral = () => {
  return (
<>



<HomeHeader/>
<CommonPageHeader title="Orals" subtitle="Orals" />
{/* <CommonPageHeader title="Coming Soon" subtitle="Coming Soon" /> */}


   <OralCard/>
         <Footer/></>
  )
}

export default Oral