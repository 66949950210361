import React, { useRef, useState } from "react";

import { Link } from 'react-router-dom';
import emailjs from "@emailjs/browser";
import toast from "react-hot-toast";
const ContactFormArea = () => {






   const form = useRef();
   const [error, setError] = useState(null);
 
   const [name, setName] = useState();
   const [email, setemail] = useState();
   const [phone, setphone] = useState();
   const [message, setmessage] = useState();
 
   const sendEmail = (e) => {
     e.preventDefault();
 
     if(!name || !email || !phone || !message) {
         toast.error("Please Fill All Fields");
     } else {
         emailjs
         .sendForm(
           "service_umguxi7",
           "template_xkhubmf",
           form.current,
           "RY6g5sa_TIqx1Pg3F"
         )
         .then(
           toast.success("Successfully Submit"),
             setName(''),
             setemail(""),
             setphone(''),
             setmessage(''),
         );
     }
 
   
    
   };
 



   return (
      <>
         <section className="contact-form-area gray-bg pt-100 pb-100">
            <div className="container">
               <div className="form-wrapper">
                  <div className="row align-items-center">
                     <div className="col-xl-8 col-lg-8">
                        <div className="section-title mb-55">
                           <p><span></span> Anything On your Mind</p>
                
                        </div>
                     </div>
                   
                  </div>
                  <div className="contact-form">
                     <form
                     
                     ref={form}
                     onSubmit={sendEmail}
                     
                     id="contact-form" action="#">
                        <div className="row">
                           <div className="col-lg-6">
                              <div className="form-box user-icon mb-30">
                                 <input
                                 
                                 value={name}
                                 onChange={(e) => setName(e.target.value)}
                                 
                                 type="text"       name="name" placeholder="Your Name" />
                              </div>
                           </div>
                           <div className="col-lg-6">
                              <div className="form-box email-icon mb-30">
                                 <input type="email"
              name="email"
                                 
                                 value={email}
                                 onChange={(e) => setemail(e.target.value)}
                                 
                                 
                                 placeholder="Your Email" />
                              </div>
                           </div>
                           <div className="col-lg-6">
                              <div className="form-box phone-icon mb-30">
                                 <input type="text" 
                                 
                                 value={phone}
                                 onChange={(e) => setphone(e.target.value)}
                                 
                                 name="phone"
                                 placeholder="Your Phone" />
                              </div>
                           </div>
                 
                           <div className="col-lg-12">
                              <div className="form-box message-icon mb-30">
                                 <textarea 
                                 
                                 value={message}
                                 onChange={(e) => setmessage(e.target.value)}
                                 
                                 name="message"
                                 id="message" cols="30" rows="10"
                                    placeholder="Your Message"></textarea>
                              </div>
                         
                           </div>
                        </div>
                        <div className='div-btn-head'>

                        <button 
                        
              onClick={sendEmail}
                        
                        className='btn-send-from'>Send</button>
                        </div>
                     </form>
                     <p className="ajax-response text-center"></p>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default ContactFormArea;