



import React from 'react'
import HomeHeader from '../Home/Home/HomeHeader/HomeHeader'
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader'
import ServicesOneABout from '../Services/ServicesOne/ServicesOneAbout/ServicesOneABout'
import CalculateArea from '../Services/ServicesOne/CalculateArea/CalculateArea'
import ServicesOneHiringArea from '../Services/ServicesOne/ServicesOneHiringArea/ServicesOneHiringArea'
import Footer from '../../components/Shared/Footer'
import Cardsinject from './Cardsinject'

const Inject = () => {
  return (
<>



<HomeHeader/>
<CommonPageHeader title="Injectables" subtitle="Injectables" />
{/* <CommonPageHeader title="Coming Soon" subtitle="Coming Soon" /> */}

<Cardsinject/>

         {/* <ServicesOneABout/> */}
         {/* <CalculateArea/>
         <ServicesOneHiringArea/> */}

         
         <Footer/></>
  )
}

export default Inject