import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ScrollTop from './components/ScrollTop';
import AllContext from './context/AllContext';

import Contact from './pages/Contact/Contact';

import Home from './pages/Home/Home/Home';

import ServicesOne from './pages/Services/ServicesOne/ServicesOne';

import './App.css';

import Oral from './pages/Products/Oral';
import Inject from './pages/Products/Inject';
import CheckAuthenticity from './pages/Products/Authenticity/Authenticity';

import toast, { Toaster } from "react-hot-toast";



function App() {
  return (
    <>
        <Toaster/>
      <AllContext>
        <BrowserRouter>

          <ScrollTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<ServicesOne />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/Oral" element={<Oral/>} />
            <Route path="/Injectable" element={<Inject/>} />
            <Route path="/CheckAuthenticity" element={<CheckAuthenticity/>} />


            
   


          </Routes>
        </BrowserRouter>
      </AllContext>
    </>
  );
}

export default App;
