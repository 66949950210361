import React from 'react';

const ContactArea = () => {
    return (
        <>
            <section className="contact-area pt-120 pb-90 " data-background="assets/img/bg/bg-map.png">
                <div className="container">
                    <div  style={{display:'flex',justifyContent:'center'}}  className="row    ">
                        <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className="contact text-center mb-30">
                                <i className="fas fa-envelope"></i>
                                <h3>Mail Here</h3>
                                <p>support@Thebiogear.com</p>
                           
                            </div>
                        </div>
                    
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContactArea;