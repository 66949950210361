/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import "./Authenticity.css";
import {
  collection,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../components/Firebase/firebase";
import { toast } from "react-hot-toast";
import "./auth.scss";
import HomeHeader from "../../Home/Home/HomeHeader/HomeHeader";
import CommonPageHeader from "../../../components/CommonPageHeader/CommonPageHeader";
import Footer from "../../../components/Shared/Footer";

const Authenticity = () => {
  const [text, setText] = useState();

  const [loading, setLoading] = useState(false);
  const [auth, setAuth] = useState(0);







  const check = async () => {
    setLoading(true);
    getDoc(doc(db, "authentication", text.toLowerCase())).then((docSnap) => {
      if (docSnap.exists()) {
        if (docSnap.data().checked === false) {
          const washingtonRef = doc(db, "authentication", text.toLowerCase());

          // Set the "capital" field of the city 'DC'
          updateDoc(washingtonRef, {
            checked: true,
          }).then(() => {
            setAuth(1);
            setLoading(false);
            toast.success("Product is Authenticated");
          });
        } else {
          setAuth(2);
          toast.error("Already Verified", {
            position: "top-right",
          });
          setLoading(false);
        }
      } else {
        setAuth(3);
        toast.error("No Code exist", {
          position: "top-right",
        });
        setLoading(false);
      }
    });
  };

  const retry = () => {
    setAuth(0);
    setLoading(false);
    setText("");
  };

  return (
<>


<HomeHeader/>
<CommonPageHeader title="CHECK AUTHENTICITY" subtitle="CHECK AUTHENTICITY" />

<div>
            {auth === 1 ? (
              <div id="container123455">
                <div id="success-box">
                  <div class="dot"></div>
                  <div class="dot two"></div>
                  <div class="face">
                    <div class="eye"></div>
                    <div class="eye right"></div>
                    <div class="mouth happy"></div>
                  </div>
                  <div class="shadow scale"></div>
                  <div class="message">
                    <h1 class="alert h1testalet">Verified!</h1>
                    <p className="ptestalet">yay, Product is Genuine.</p>
                  </div>
                  <button
                    onClick={() => retry()}
                    class="button-box buttontestalet"
                  >
                    <h1 class="green h1testalet">continue</h1>
                  </button>
                </div>
              </div>
            ) : (
              <>
                {auth === 2 ? (
                  <>
                    <div id="container123455">
                      <div id="error-box">
                        <div class="dot"></div>
                        <div class="dot two"></div>
                        <div class="face2">
                          <div class="eye"></div>
                          <div class="eye right"></div>
                          <div class="mouth sad"></div>
                        </div>
                        <div class="shadow move"></div>
                        <div class="message">
                          <h1 class="alert h1testalet">Already Verified!</h1>
                          <p className="ptestalet">
                            oh no, something went wrong.
                          </p>
                        </div>
                        <button
                          onClick={() => retry()}
                          class="button-box buttontestalet"
                        >
                          <h1 class="red h1testalet">try again</h1>
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {auth === 3 ? (
                      <div id="container123455">
                        <div id="error-box">
                          <div class="dot"></div>
                          <div class="dot two"></div>
                          <div class="face2">
                            <div class="eye"></div>
                            <div class="eye right"></div>
                            <div class="mouth sad"></div>
                          </div>
                          <div class="shadow move"></div>
                          <div class="message">
                            <h1 class="alert h1testalet">Code Doesn't Exist</h1>
                            <p className="ptestalet">
                              oh no, something went wrong.
                            </p>
                          </div>
                          <button
                            onClick={() => {
                              retry();
                            }}
                            class="button-box buttontestalet"
                          >
                            <h1 class="red h1testalet">try again</h1>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="auth-1">
                          <div className="auth-2">
                         
                            <div className="auth-p1">
                              <p>
                                Enter the serial number of an item you own or
                                are attempting to buy to confirm its
                                authenticity.
                              </p>
                            </div>
                            {loading === true ? (
                              <>
                                <div className="loading">
                                  <div class="hourglass"></div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            <></>
                            <div className="section-auth-2">
                              <div>
                                <input
                                  value={text}
                                  onChange={(e) => setText(e.target.value)}
                                  className="input-auth1"
                                  type="text"
                                  name=""
                                  id=""
                                />
                              </div>
                              <div>
                                <button
                                  onClick={() => check()}
                                  className="bb-no"
                                >
                                  Check Authenticity
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>

          <Footer/>


</>
  );
};

export default Authenticity;
