import React from 'react';
import { Link } from 'react-router-dom';

const CommonPageHeader = ({title,subtitle}) => {
   return (
      <>
         <section className="breadcrumb-bg pt-20 pb-20 page_header_bg">
         <h1  style={{fontSize:'40px'}}
         
         
         className=' text-center
    
    text-transform: uppercase '>{title}</h1>
         </section>
      </>
   );
};

export default CommonPageHeader;